import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Line } from 'react-konva';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import SolarPanel from '../Panel/SolarPanel';
import { useKonvaScale } from 'hooks/useKonvaScale';
import { PANEL_OFF_COLOR, STROKE_COLOR_FOR_USER_MAPPED_PANEL } from '../../../constants';
import { resizePoints } from '../util';
import Konva from 'konva';

interface Props {
	panelsObj: [string, Array<RasterSolarPanel & { userMapped:boolean }>]
}

export default function PanelsWithHull({panelsObj}: Props){
	const { allRoofSegs} = useSelector((state: RootState) => state.roofData.data);
	const { deletedPanels, activePanelMode, newlyAddedPanels } = useSelector((state: RootState) => state.panelSlice.data);
	const currentFacet = allRoofSegs.find(eachFacet => eachFacet.id === panelsObj[0]);

	const scale = useKonvaScale();
	const isIndividualPanelOperation= activePanelMode;

	const facetRef = useRef<Konva.Line | null>(null);

	const transformHullCoords = useCallback((hullCoords: RasterRoofSegment['hullCoords']|undefined) => {
		return hullCoords?.flat().map((point, index) => index % 2 === 0 ? point * scale.x : point * scale.y);
	},[scale]);

	const deletedPanelIds = useMemo(() => deletedPanels.map(p => p.panelId), [deletedPanels]);

	const transformNewlyAddedPanel = useMemo(() => (
		newlyAddedPanels.map((p)=>({...p, exteriorCoords: resizePoints(p.exteriorCoords.flat(), scale)}))
	),[scale, newlyAddedPanels]);

	useEffect(()=>{
		//ToDO : need to thik of a proper ay using refcontext
		if(facetRef.current){
			facetRef.current.fill('transparent');
		}
	},[isIndividualPanelOperation, facetRef.current]);

	return (
		<>
			<Line
				ref={facetRef}
				name='movable-facet'
				id={panelsObj[0]}
				closed={true}
				strokeWidth={1.4}
				points={transformHullCoords(currentFacet?.simplifiedHullCoords)}
				visible={isIndividualPanelOperation}
			/>
			{
				panelsObj[1].map(solarPanelData => {
					if(deletedPanelIds.includes(solarPanelData.id)) return null;
					return (
						<SolarPanel
							key={solarPanelData.id}
							panelData={{...solarPanelData, azimuth: currentFacet?.azimuthDegrees || 0}}
							panelId={solarPanelData.id}
						/>
					);
				})
			}
			{
				transformNewlyAddedPanel.map((p, index) => (
					<Line
						key={`${p.roofSegmentId}${index}`}
						stroke={STROKE_COLOR_FOR_USER_MAPPED_PANEL}
						closed={true}
						strokeWidth={1.4}
						points={p.exteriorCoords.flat()}
						fill={PANEL_OFF_COLOR}

					/>

				))
			}
		</>
	);
}
