import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ModalPopup, ModalcontentInfo, ModalbuttonWrap, Modalbutton, ModalbuttonNo } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setAddPanel, setPanelMove, togglePanelModal } from 'store/slices/PanelSlice';

const popupMsg = 'Are you sure you want to cancel Moved Panels?';

export default function CancelMovePanelModal() {
	const open  = useSelector((state: RootState) => state.panelSlice.data.cancelMovePanelModal);

	const dispatch = useDispatch<AppDispatch>();

	const handleYes = () => {
		dispatch(setPanelMove({ shouldEnablePanelMove: false }));
		dispatch(togglePanelModal({targetModal: 'CANCEL_MOVE_PANEL', val:false}));
	};

	const handleNo = () => {
		dispatch(togglePanelModal({targetModal: 'CANCEL_MOVE_PANEL', val:false}));
	};

	return (
		<Popup
			contentStyle={{ width: '560px', borderRadius: '4px' }}
			open={open}
			closeOnDocumentClick={false}
			onClose={handleNo}>
			<ModalPopup>
				<ModalcontentInfo>
					{popupMsg}
				</ModalcontentInfo>
				<ModalbuttonWrap>
					<Modalbutton onClick={handleYes}>
						Yes
					</Modalbutton>
					<ModalbuttonNo onClick={handleNo}>
						No
					</ModalbuttonNo>
				</ModalbuttonWrap>
			</ModalPopup>
		</Popup>
	);
}