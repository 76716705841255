
enum PANEL_ENERGY_COLORS {
	HIGH = 'rgba(255, 209, 89, 0.5)',//'#FFD159',
	MEDIUM = 'rgba(255, 147, 43, 0.5)', //'#FF932B',
	LOW = 'rgba(222, 91, 18, 0.5)'
}

const PANEL_OFF_COLOR = 'rgba(131, 134, 141, 0.1)';
const PANEL_STROKE_COLOR = '#FFFFFF';
const STROKE_COLOR_FOR_USER_MAPPED_PANEL = '#FEB517';

const SEMI_TRANPARENT_BLACK_PANEL = 'rgba(0,0,0,0.4)';
const FULL_TRANPARENT_BLACK_PANEL = 'rgba(0,0,0,0.1)';

const ENERGY_THRESHOLD_THAT_A_PANEL_MUST_HAVE = 480;

const MIN_PANELS_REQUIRED_FOR_DESIGN_TO_QUALIFY = 3;

const MIN_PANELS_REQUIRED_FOR_ROOF_SEGMENT_TO_QUALIFY = 2 ;
const MIN_PANELS_REQUIRED_FOR_ROOF_SEGMENT_TO_DISPLAY= 3 ;

const SOlAR_QOUTE_APP_NAME = 'SOLARQUOTE';
const SUBHUB_APP_NAME = 'SUBHUB';

enum PARENT_EVENTS {
	DESIGN_HAS_LESS_THEN_REQUIRED_PANELS = 'SOLARQUOTEEMBED_BAD_DESIGN',
	DESIGN_HAS_SUFFICIENT_PANELS = 'SOLARQUOTEEMBED_DESIGN_DATA_LOADED',
	SET_HOUSE = 'SOLARQUOTEEMBED_SET_HOUSE',
	PANEL_CHANGED = 'SOLARQUOTEEMBED_PANEL_CHANGED',
	SAVE_DESIGN_TRIGGER = 'SOLARQUOTEEMBED_SAVE_HOUSE_TRIGGERED',
	ENERGY_INPUTS_CHANGED = 'energyInputsChanged',
	FULL_VIEW_TOGGLE= 'FULL_VIEW_MODE',
	SAVE_DESIGN = 'SOLARQUOTEEMBED_SAVE_DESIGN',
	DESIGN_SYNCED = 'PARENT_SYNCED_WITH_TOOL',
	DESIGN_UNSYNCED = 'PARENT_UNSYNCED_WITH_TOOL',
}

const ENERGY_DERATE_RATE = 15;
const DEFAULT_ELECTRIC_UNIT_PRICE = 26.5;
const AZIMUTH_CONSTRAINT = 30;
const MONTHS = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec', 'Year'];
const MAX_ZOOM_LIMIT= 3;
const MAX_ZOOM_LIMIT_FOR_SAVE= 1.5;
const MIN_ZOOM_LIMIT= .25;
const DEFAULT_KONVA_SCALE = 1;
const DEFAULT_KONVA_STAGE_POSITION = { x: 0, y: 0 };
const IRRADIANCE_UPRATE_RATE = 15;
const FULL_VIEW_POSITION_SHIFT_FACTOR= .30;

enum IMAGERY_PROVIDER{
	NEARMAP= 'nearmap',
	SUNROOF= 'sunroof'
}

const IMAGERY_PROVIDER_KEY = {
	[IMAGERY_PROVIDER.NEARMAP]: 'nearmap',
	[IMAGERY_PROVIDER.SUNROOF]: 'solarInfo',
};

const BREAK_POINT_FOR_MOBILE_VIEW= 935;

export {
	PANEL_ENERGY_COLORS,
	PANEL_OFF_COLOR,
	PANEL_STROKE_COLOR,
	ENERGY_THRESHOLD_THAT_A_PANEL_MUST_HAVE,
	MIN_PANELS_REQUIRED_FOR_DESIGN_TO_QUALIFY,
	PARENT_EVENTS,
	SOlAR_QOUTE_APP_NAME,
	ENERGY_DERATE_RATE,
	DEFAULT_ELECTRIC_UNIT_PRICE,
	SUBHUB_APP_NAME,
	AZIMUTH_CONSTRAINT,
	SEMI_TRANPARENT_BLACK_PANEL,
	FULL_TRANPARENT_BLACK_PANEL,
	MONTHS,
	MIN_PANELS_REQUIRED_FOR_ROOF_SEGMENT_TO_QUALIFY,
	MAX_ZOOM_LIMIT,
	MIN_ZOOM_LIMIT,
	MIN_PANELS_REQUIRED_FOR_ROOF_SEGMENT_TO_DISPLAY,
	DEFAULT_KONVA_SCALE,
	DEFAULT_KONVA_STAGE_POSITION,
	STROKE_COLOR_FOR_USER_MAPPED_PANEL,
	IRRADIANCE_UPRATE_RATE,
	IMAGERY_PROVIDER,
	IMAGERY_PROVIDER_KEY,
	FULL_VIEW_POSITION_SHIFT_FACTOR,
	BREAK_POINT_FOR_MOBILE_VIEW,
	MAX_ZOOM_LIMIT_FOR_SAVE
};
